import React from "react"
import ColorshelfPage from "../../components/colorlist/colorshelfpage"

const colorlist = require('../../content/custom/colorlist/shelf2.json');


const ColorlibraryShelf2Page = () => (
	<ColorshelfPage
			activePath={"colorlibrary/shelf2"}
			colorList={colorlist}
		/>
)

export default ColorlibraryShelf2Page
